<template>
  <div class="chats">
    <div
      v-for="(msgData, index) in chat"
      :key="msgData.senderId + String(index)"
      class="chat"
      :class="{ 'chat-left': msgData.senderId === counselor.user_id }"
    >
      <div class="chat-avatar">
        <b-avatar
          v-if="!index || (index && msgData.senderId !== chat[index-1].senderId)"
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="getProfileImage(msgData.senderId)"
        />
      </div>
      <div class="chat-body">
        <div :key="msgData.time" class="chat-content">
          <p>{{ msgData.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import { FILESURL } from "@/config";

export default {
  components: {
    BAvatar,
  },
  props: {
    chat: {
      type: Array,
      default: [],
      required: true,
    },
    counselor: {
      type: Object,
      default: {},
      required: true,
    },
    student: {
      type: Object,
      default: {},
      required: true,
    },
  },
  methods: {
    getProfileImage(senderId) {
      if (senderId === this.$props.counselor.user_id) {
        return FILESURL + this.$props.counselor.user_profile_image;
      } else {
        return FILESURL + this.$props.student.user_profile_image;
      }
    },
  },
};
</script>

<style>
</style>
